// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import jquery from "jquery"

window.jQuery = jquery
window.$ = jquery

import * as bootstrap from "bootstrap"
import * as datepicker from "bootstrap-datepicker"

require("bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js")

import "./task_form_configurator"
import "./task_group_configurator"

import "./controllers"
